a {
    color: rgba(0, 0, 0, 0.87);
}

.labelmargin {
    margin: 0 0 0.28571429rem 0;
}

.datepicker-container .field {
    margin: 0em !important;
}

.menu img {
    height: 2rem;
    width: 100%;
}

.editor-class {
    background-color: rgba(0,0,0,0.05);
    padding: 1rem;
    /* border: 1px solid #ccc; */
}

.toolbar-class {
    border: 1px solid #ccc;
}

.ck-editor__editable {
    min-height: 300px;
}